<template>
  <el-cascader ref="dictCascader"
               v-if="isShowAddressInfo"
               v-model="dictArrValue" :options="dictOptions" :size="size" clearable :props="props">
  </el-cascader>
</template>

<script>
import { getDictionary } from '@/apis/authManage/dataDictionary'
export default {
  name: 'DataCascader',
  data () {
    return {
      dictOptions: [],
      isShowAddressInfo: true
    }
  },
  created () {
    getDictionary({ dictCode: this.dictCode }).then((res) => {
      const activityData = (res.data || []).filter(item => item.activity)
      // 转树型数据
      const treeData = this.toTree(activityData)
      this.dictOptions = treeData
      /* const parentArr = this.parentIds(this.dictOptions, this.value)
      console.log(777777, this.dictOptions, parentArr)
      this.dictArrValue = parentArr
      // this.modalKey++
      console.log(78, this.modalKey) */
    })
  },
  // 实现子组件，父级组件双向绑定
  /*  mounted () {
    this.isShowAddressInfo = false
    // 这里搞个定时器重新载入一下组件就可以触发组件拉取数据
    setTimeout(() => {
      this.isShowAddressInfo = true
    }, 500)
  }, */
  computed: {
    dictArrValue: {
      get () {
        const parentArr = this.parentIds(this.dictOptions, this.value)
        return parentArr
      },
      set (val) {
        const value = val[val.length - 1]
        this.$emit('update:modelValue', value)
        // this.$emit('toChangeSelection', value)
      }
    }
  },
  props: {
    dictCode: {
      type: String,
      default: ''
    },
    /* value: {
      type: Array,
      default () {
        return []
      }
    }, */
    clearable: {
      type: Boolean,
      default () {
        return false
      }
    },
    props: {
      type: Object
    },
    size: {
      type: String,
      default () {
        return 'small'
      }
    },
    value: { // 必须要使用value
      type: Number
      // default: () => {}
    },
    modelValue: {
      type: Number
      // default: () => {}
    }
  },
  methods: {
    // 通过子节点id,计算完整的父子级选中路径数组，便于赋值
    parentIds (arr1, id) {
      const temp = []
      const forFn = function (arr, id) {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i]
          if (item.dictValue == id) {
            forFn(arr1, item.dictParentValue)
            temp.push(item.dictValue)
            break
          } else {
            if (item.children) {
              forFn(item.children, id)
            }
          }
        }
      }
      forFn(arr1, id)
      return temp
    },
    toTree (data) {
      const result = []
      if (!Array.isArray(data)) {
        return result
      }
      data.forEach(item => {
        delete item.children
      })
      const map = {}
      const newData = []
      data.forEach(item => {
        newData.push(item = {
          value: item.dictValue,
          label: item.dictName,
          leaf: true,
          dictParentValue: item.dictParentValue,
          dictValue: item.dictValue
        })
      })
      newData.forEach(item => {
        map[item.dictValue] = item
      })
      newData.forEach(item => {
        const parent = map[item.dictParentValue]
        if (parent) {
          (parent.children || (parent.children = [])).push(item)
          parent.leaf = false
        } else {
          result.push(item)
        }
      })
      return result
    }
  }
}
</script>

<style scoped>

</style>
